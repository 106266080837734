:root {
    --dark-500: #212225;
    --dark-400: #4b4a51;
    --dark-300: #7e7f84;
    --dark-200: #a3a2aa;
    --dark-100: #cecfd2;
    --primary-900: #985e01;
    --primary-700: #e38e02;
    --primary-500: #fdb135;
    --primary-300: #fec467;
    --primary-100: #fed89a;
    --primary-25: #ffeed3;
}
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFWkU1Z4Y.woff2)
        format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFU0U1Z4Y.woff2)
        format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFVUU1Z4Y.woff2)
        format("woff2");
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFWUU1Z4Y.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFV0U1.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

/*font*/
.h1-ligth {
    font-size: 3.3rem;
    font-style: normal;
    font-weight: 300;
    line-height: 117%;
    letter-spacing: 0px;
    text-align: left;
    color: var(--dark-500);
    overflow-wrap: break-word;
}
.h1-medium {
    font-style: normal;
    font-weight: 500;
    font-size: 3.3rem;
    line-height: 117%;
    color: var(--dark-500);
    margin-top: 0.8rem;
    overflow-wrap: break-word;
}
.h1-figma {
    font-size: 3rem;
    color: var(--dark-500);
}

.h2-medium {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 117%;
    text-align: center;
    letter-spacing: -0.02em;
}

.titulo-home {
    /*no se esta usando*/
    font-style: normal;
    font-weight: 300;
    /*font-size: 2rem;*/
    line-height: 117%;
    /* or 37px */
    letter-spacing: -0.02em;
    color: var(--dark-500);
}

.h3-regular {
    color: var(--dark-300);
    font-size: 1.5rem;
}

.h3-medium {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 117%;
    letter-spacing: -0.02em;
    color: var(--dark-500);
    text-align: center;
}

.h4-regular {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--dark-500);
}

.p1-regular {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
}

.p1-medium {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 117%;
    text-align: center;
}

.p2-regular {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 117%;
    /* or 15px */
    text-align: center;
    color: #000000;
}

.chip {
    background-color: var(--primary-100);
    border-radius: 10px;
    color: #232323;
    width: 13rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.chip-M {
    background-color: var(--primary-100);
    border-radius: 10px;
    color: #232323;
    width: 13rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

body {
    background-color: #f8f9fa;
}
.navbar {
    grid-area: navbar;
    background-color: white !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.search {
    background-color: var(--primary-500);
    border: none;
    padding: 0.5rem 1.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.tag-button {
    border: var(--primary-500) !important;
    border-radius: 8px;
}

.tag-button:hover {
    background-color: var(--primary-500);
    color: #212225;
}
.tag-button:active {
    background-color: white;
    color: #212225;
    box-shadow: 0 0 0 0.25rem var(--primary-500);
}
.tag-button:focus {
    box-shadow: 0 0 0 0.25rem var(--primary-500);
}

.tag-button-create {
    width: 30%;
    float: right;
    margin-right: 5.5rem;
    margin-bottom: 1.5rem;
}

.box-shadow-primary:focus {
    box-shadow: 0 0 0 0.25rem var(--primary-500);
}
.box-shadow-primary:focus {
    box-shadow: 0 0 0 0.25rem var(--primary-500);
}

.nav-item {
    padding: 1rem 1rem 1rem 1rem;
}

.input-search {
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 4rem;
}
.section-search {
    margin-top: 9rem;
    align-self: center;
}
.input-search:focus {
    box-shadow: 0 0 0 0.25rem var(--primary-500);
}

.home {
    display: grid;
    grid-template-areas: "search micro ";
    grid-auto-columns: 50% 50%;
    padding-top: 5rem;
    min-height: 100vh;
}

.micro {
    grid-area: micro;
    /*background: url("./components/Micro.png") center center no-repeat;*/
    /*background-size: auto;*/
    /*width: 300px;*/
    justify-self: end;
}

.search-home {
    grid-area: search;
    margin-left: 2rem;
}

.card-radio-tag {
    border-radius: 10px;
    border: 1px solid #cecfd2;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
}
.card-radio-tag:last-child {
    margin-bottom: 1.5rem;
}

.lista-resultados {
    padding-left: 0;
    color: var(--dark-400);
}

/*TAG*/
.tag-view {
}
.card-tag {
    background-color: #f8f9fa;
    border: none;
}

.pie-imagen {
    font-family: "Helvetica Neue", serif;
    text-align: center;
    color: var(--dark-300);
}

.button-share {
    border: var(--primary-500) solid !important;
}

.button-share:before {
    content: "";
    width: 50px;
    height: 30px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    background: transparent url("./views/Vector.png") no-repeat center center;
}

/*drawer*/
.button-drawer {
    border: var(--primary-500) solid !important;
}
.imagen-drawer {
}

.yt-video {
    width: 100%;
    height: 600px;
}
.card-error {
    width: 50%;
    height: 50%;
}

.isDisabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
}

.size-search {
    width: 75%;
    margin-top: 10%;
}
.size-search-input {
    height: 8%;
}

.btn-add-resources {
    border: var(--primary-500) solid !important;
}
.btn-add-resources:focus {
    box-shadow: none;
}

.element {
    height: 100vh;
    /*background-color: #ededed;
   font-size: 45px;
   border-top: 1px solid #000;
   padding-top: 55px;
   padding-left: 10px;*/
}

/*AboutUs*/
.aboutus {
    background-color: var(--primary-500);
}
.aboutus-grid {
    display: grid;
    grid-template-areas: "column1 column2";
    grid-auto-columns: 50% 50%;
    height: 100vh;
}
.column1 {
    grid-area: column1;
    padding: 5rem;
}
.column2 {
    grid-area: column2;
    padding: 5rem;
    display: flex;
    flex-direction: column-reverse;
}
.h1-medium-white {
    font-style: normal;
    font-weight: 500;
    font-size: 3.3rem;
    line-height: 117%;
    color: white;
    margin-top: 0.8rem;
    padding-bottom: 3rem;
}

.h3-regular-white {
    color: white;
    font-size: 1.5rem;
}
/* Edit tag view */
.edit-tag-view-card {
    display: grid;
    grid-template-areas: "editTag previewTag";
    grid-auto-columns: 50% 50%;
    margin-top: 3rem !important;
}
.edit-tag {
    grid-area: editTag;
}
.preview-tag {
    grid-area: previewTag;
}
/* User view */
.word-break {
    word-break: break-word;
}
.user-view {
    display: grid;
    grid-template-areas:
        "image name"
        "image description";
    grid-auto-columns: 26% 80%;
}
.image {
    grid-area: image;
    max-height: 220px;
    max-width: 220px;
    border-radius: 5%;
}
.name-user {
    grid-area: name;
    font-style: normal;
    font-weight: 500;
    font-size: 3rem;
    line-height: 100%;
    color: var(--dark-500);
    margin-top: 0.8rem;
}
.description-user {
    grid-area: description;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: var(--dark-500);
}

/*FAQs*/
.faqs {
    padding: 2rem;
    min-height: 100vh;
}

.accordion-button:not(.collapsed) {
    color: #e38e02;
    background-color: #fed89a;
}
.accordion-button:not(.collapsed)::after {
    background-image: url(
        data:image/svg + xml,
        %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"fill="%23212529"%3e%3cpathfill-rule="evenodd"d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e
    );
}
.accordion-button:focus {
    border-color: #fed89a !important;
    box-shadow: 0 0 0 0.25rem rgb(254 216 154 / 25%);
}

/*FOOTER*/
.footer {
    background-color: var(--primary-500);
}

.h4-regular-white {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
}

@media only screen and (max-width: 350px) {
    .h1-medium-white {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 117%;
        color: white;
        padding-bottom: 0;
    }
    .h3-regular-white {
        color: white;
        font-size: 0.8rem !important;
    }
}

@media only screen and (max-width: 992px) and (min-width: 576px) {
    .column1 {
        display: flex;
        align-items: center;
    }
    .h3-regular-white {
        color: white;
        font-size: 1.5rem !important;
    }
    .column2 {
        align-items: center;
    }
    .user-view {
        display: grid;
        grid-template-areas:
            "image name"
            "image description";
        grid-auto-columns: 33% 80%;
    }
    .image {
    }
    .name-user {
        padding-top: 0.5em;
        font-size: 2.5rem;
    }
    .description-user {
        font-size: 1.5rem;
    }
}

/*query para mobile*/
@media only screen and (max-width: 992px) {
    .home {
        grid-template-areas:
            "search"
            "micro";
        grid-auto-columns: 100% 100%;
        min-height: 100vh;
        padding-top: 0rem;
    }

    .search-home {
        grid-area: search;
        width: 90%;
        justify-self: center;
        align-self: center;
        margin-left: 0rem;
    }

    .section-search {
        margin-top: 0rem;
    }

    .micro {
        z-index: -1000;
        grid-area: micro;
        /*background: url("./components/Micro.png") center center no-repeat;*/
        /*background-size: 600px;*/
        width: 100%;
        justify-self: center;
    }
    .micro-img {
    }
    .h1-ligth {
        font-size: 2rem;
    }
    .h1-medium {
        font-size: 2rem;
    }

    .aboutus-grid {
        display: grid;
        grid-template-areas:
            "column1"
            "column2";
        grid-auto-columns: 100% 100%;
        grid-template-rows: 50% 50%;
        height: 100vh;
    }

    .column1 {
        grid-area: column1;
        padding: 3rem;
    }
    .column2 {
        grid-area: column2;
        padding: 3rem;
        display: flex;
        flex-direction: inherit;
    }
    .h1-medium-white {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 117%;
        color: white;
        padding-bottom: 0;
    }
    .h3-regular-white {
        color: white;
        font-size: 1rem;
    }
    .faqs {
        padding: 1rem;
    }
    .select {
        margin-left: 28px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .user-view {
        grid-template-areas:
            "image name"
            "image description";
        grid-auto-columns: 35% 80%;
        padding-left: 1em;
        padding-right: 1em;
    }
    .image {
        grid-area: image;
        max-height: 150px;
        max-width: 150px;
        border-radius: 5%;
    }
    .name-user {
        padding-top: 0.5rem;
        font-size: 2rem;
    }
    .description-user {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 576px) {
    .user-view {
        grid-template-areas:
            "image"
            "name"
            "description";
        grid-auto-columns: 100%;
        padding-left: 1em;
        padding-right: 1em;
    }
    .image {
        grid-area: image;
        max-height: 150px;
        max-width: 150px;
        border-radius: 5%;
    }
    .name-user {
        font-size: 2rem;
    }
    .description-user {
        font-size: 1.2rem;
    }
}

.font-tag {
    font-family: Rubik;
}

.container {
    width: 90%;
}

.container-fluid {
    width: 100%;
}

.no-text-underline {
    text-decoration: none !important;
}

.form-control:focus {
    border-color: var(--primary-500);
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-300);
}

.button-nav {
    background-color: white;
    border: var(--primary-500) !important;
    border-radius: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.navbar-nav .nav-link {
    padding-right: 5px;
    padding-left: 5px;
}

.primary-button {
    background-color: var(--primary-500);
}

.valid-loading {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #6c757d;
}
.profile-button {
    padding-left: 30px;
    width: 100%;
}
.profile-button > svg {
    margin-right: 10px;
}
